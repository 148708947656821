import { useRef, useState } from "react";
import Home from "./components/home";
import Tools from "./components/tools";
import Products from "./components/products";
import Coverage from "./components/coverage";
import Customers from "./components/customers";
import Contact from "./components/contact";
import Footer from "./components/footer";
import spa from "./language/spa";
import eng from "./language/eng";
import "./App.scss";

const MainPage = () => {
  const [language, setLanguage] = useState<string>(navigator.language);
  const languageData = language.includes('es') ? spa : eng;
  const tools = useRef<null | HTMLDivElement>(null);;
  const products = useRef<null | HTMLDivElement>(null);;
  const customers = useRef<null | HTMLDivElement>(null);;
  const contact = useRef<null | HTMLDivElement>(null);;

  const scrollToTools = () => tools.current?.scrollIntoView();
  const scrollToProducts = () => products.current?.scrollIntoView();
  const scrollToCustomers = () => customers.current?.scrollIntoView();
  const scrollToContact = () => contact.current?.scrollIntoView();

  return (
    <div className="container">
      <Home
        scrollToTools={scrollToTools}
        scrollToProducts={scrollToProducts}
        scrollToCoverage={scrollToCustomers}
        scrollToContact={scrollToContact}
        languageDataHeader={languageData.header}
        languageDataHome={languageData.home}
        setLanguage={setLanguage}
        language={language}
      />
      <div ref={tools}>
        <Tools
          languageData={languageData.tools}
        />
      </div>
      <div ref={products}>
        <Products
          languageData={languageData.products}
          scrollToContact={scrollToContact}
          language={language}
        />
      </div>
        <Coverage
          languageData={languageData.coverage}
        />
      <div ref={customers}>
        <Customers 
          languageData={languageData.customers}
          scrollToContact={scrollToContact}
          language={language}
        />
      </div>
      <div ref={contact}>
      <Contact 
        languageData={languageData.contact}
      />
      </div>
      <Footer
        scrollToTools={scrollToTools}
        scrollToProducts={scrollToProducts}
        scrollToCoverage={scrollToCustomers}
        scrollToContact={scrollToContact}
        languageData={languageData.footer}
      />
    </div>
  )};

  export default MainPage;
